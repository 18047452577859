<script setup>
import { ref, reactive, defineEmits } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanKeuanganService from '@/services/LaporanKeuanganService'
import useVuelidate from '@vuelidate/core'
import { formatCurrency } from '@/helpers'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanKeuanganService()

let isLoadingExport = ref(false)
let isLoadingPreview = ref(false)
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let form = reactive({
  date_from: null,
  date_to: null,
})

let submitted = ref(false)

let items = ref([])
let totalRecords = ref(0)

let totalDebit = ref(0)
let totalKredit = ref(0)

const rules = {
  date_from: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  date_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

function onPreview(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  isLoadingPreview.value = true
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')

  laporanService
    .getLaporanTrialBalance(startDate, endDate)
    .then((res) => {
      items.value = res.data.data
      totalRecords.value = res.data.total
      isLoadingPreview.value = false

      if (items.value) {
        if (items.value.length > 0) {
          totalDebit.value = items.value.reduce(function (total, item) {
            return total + item.debit
          }, 0)
          totalKredit.value = items.value.reduce(function (total, item) {
            return total + item.kredit
          }, 0)
        }
      }
    })
    .catch((err) => {
      errorHandler(err, 'Preview Buku Besar', toast)
    })
    .finally(() => (isLoadingPreview.value = false))
}

function onExport(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  isLoadingExport.value = true
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')

  laporanService
    .exportLaporanTrialBalance(startDate, endDate)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'trial-balance.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Data Trial Balance', toast)
    })
    .finally(() => (isLoadingExport.value = false))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  isLoadingExport.value = false
  isLoadingPreview.value = false
  form.date_from = null
  form.date_to = null
}
</script>

<template>
  <fx-report-container
    title="Laporan Trial Balance"
    :loading="isLoadingExport"
    @toggle="onToggle"
    @export="onExport"
  >
    <template #content>
      <form>
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_from.$invalid && submitted,
              }"
              placeholder="Tanggal (dari)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_from.$invalid && submitted) ||
                v$.date_from.$pending.$response
              "
              class="p-error"
              >{{ v$.date_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-5">
            <Button
              :loading="isLoadingPreview"
              class="p-button-success mr-2"
              @click="onPreview(!v$.$invalid)"
              type="button"
              icon="pi pi-table"
              label="Preview"
            />
            <Button
              :loading="isLoadingExport"
              @click="onExport(!v$.$invalid)"
              type="button"
              icon="pi pi-file-pdf"
              label="Cetak"
            />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>

      <DataTable
        :value="items"
        :row-hover="true"
        :loading="isLoadingPreview"
        :paginator="false"
        :scrollable="true"
        :lazy="true"
        :totalRecords="totalRecords"
        filterDisplay="none"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
        class="p-datatable-sm"
      >
        <template #empty>
          <div class="text-center">Tidak ada data.</div>
        </template>
        <template #loading>
          <div class="text-center">Memuat data, harap tunggu...</div>
        </template>
        <Column
          field="kode_perkiraan"
          header="Kode Perkiraan"
          style="width: 5%"
        >
          <template #body="{ data }">
            {{ data.kode_account }}
          </template>
        </Column>
        <Column
          field="nama_perkiraan"
          header="Nama Perkiraan"
          style="width: 50%"
        >
          <template #body="{ data }">
            {{ data.nama_account }}
          </template>
        </Column>
        <Column
          field="debet"
          header="Debit"
          style="width: 25%; justify-content: flex-end"
        >
          <template #body="{ data }">
            {{ formatCurrency(data.debit, 0, 0) }}
          </template>
        </Column>
        <Column
          field="credit"
          header="Kredit"
          style="width: 25%; justify-content: flex-end"
        >
          <template #body="{ data }">
            {{ formatCurrency(data.kredit, 0, 0) }}
          </template>
        </Column>

        <template #footer>
          <table width="100%">
            <tr>
              <td style="width: 100%">
                <table>
                  <tr>
                    <td align="center">TOTAL</td>
                    <td style="width: 33.7%" align="right" class="text-right">
                      {{ formatCurrency(totalDebit, 0, 0) }}
                    </td>
                    <td style="width: 33.7%" align="right" class="text-right">
                      {{ formatCurrency(totalKredit, 0, 0) }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </template>
      </DataTable>
    </template>
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
